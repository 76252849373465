<template>
  <div>
    <!-- Loading -->
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>

    <main-header />

    <!-- Breadcrumb -->
    <div class="container">
      <div class="_flex _center">
        <nav aria-label="breadcrumb" style="height:40px!important">
          <ol class="breadcrumb  bg-transparent tiny">
            <li class="breadcrumb-item"><a href="/">Accueil</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Commander
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <section class="container">
      <br />
      <div class="contained">
        <div class="text-center">
          <h2><b>Commander</b></h2>
          <p>Shop is a demo app - form data will not be sent</p>
        </div>
        <br /><br />
        <!--  -->
        <div class="row">
          <div class="col-12 col-md-6">
            <h5>Information du compte</h5>
            <div class="form-group">
              <label>Nom</label>
              <input type="text" class="form-control" />
            </div>
            <div class="form-row">
              <div class="col-6 form-group">
              <label>Post-nom</label>
              <input type="text" class="form-control" />
            </div>
            <div class="col-6 form-group">
              <label>Prénom</label>
              <input type="text" class="form-control" />
            </div>
            </div>
            <div class="form-row">
              <div class="col-6 form-group">
                <label>Email</label>
                <input type="text" class="form-control" />
              </div>
              <div class="col-6 form-group">
                <label>Téléphone</label>
                <input type="text" class="form-control" />
              </div>
            </div>
            <h5>Adresse de livraison</h5>
            <div class="form-group">
              <label>Adresse</label>
              <input type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label>Ville</label>
              <input type="text" class="form-control" />
            </div>
            <div class="form-row">
              <div class="col-6 form-group">
                <label>Province</label>
                <input type="text" class="form-control" />
              </div>
              <div class="col-6 form-group">
                <label>Code Postal</label>
                <input type="text" class="form-control" />
              </div>
            </div>
            <div class="form-group">
              <label>Pays</label>
              <select class="custom-select">
                <option
                  :value="country.id"
                  v-for="country in countries"
                  :key="country.id"
                  >{{ country.name }}</option
                >
              </select>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <h5>Méthode de paiement</h5>
            <div class="form-group">
              <label>Cardholder Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label>Card Number</label>
              <input type="text" class="form-control" />
            </div>
            <label>Expiry</label>
            <div class="form-row">
              <div class="col-4 form-group">
                <select class="custom-select">
                  <option value="">Jan</option>
                  <option value="">Fev</option>
                  <option value="">Mar</option>
                  <option value="">Avr</option>
                  <option value="">Mai</option>
                  <option value="">Juin</option>
                  <option value="">Juil</option>
                  <option value="">Aout</option>
                  <option value="">Sept</option>
                  <option value="">Oct</option>
                  <option value="">Nov</option>
                  <option value="">Dec</option>
                </select>
              </div>
              <div class="col-4 form-group">
                <select class="custom-select">
                  <option value="">2020</option>
                  <option value="">2021</option>
                  <option value="">2022</option>
                  <option value="">2023</option>
                  <option value="">2024</option>
                  <option value="">2025</option>
                  <option value="">2026</option>
                  <option value="">2027</option>
                  <option value="">2028</option>
                </select>
              </div>
              <div class="col-4 form-group">
                <input type="text" placeholder="CVV" class="form-control" />
              </div>
            </div>

            <!--  -->
            <br />
            <h5 class="mb-3">Order Summary</h5>
            <table class="table table-bordered">
              <tr v-for="item in cart" :key="item.id">
                <td>{{ item.artwork.name }}</td>
                <td class="text-right">${{ item.total_prices | number }}</td>
              </tr>
            </table>
            <br />
            <div class="">
              <button
                class="btn btn-action black-outline border-2 text-uppercase px-4"
              >
                Valider la commande
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <br /><br /><br />

    <main-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    loading: false,
    country: null,
    address: "",
    email: "",
    phone_number: "",
    firstname: "",
    lastname: "",
    middlename: "",
    city: "",
    province: "",
    postal_code: "",
  }),
  computed: {
    ...mapGetters(["cart", "countries", "user"]),
  },
};
</script>

<style></style>
